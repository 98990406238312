@import "src/styles/colors.scss";

.Text {
    font-family: Quicksand;
    text-align: left;
    color: val(--kantherm-text);

    line-height: 1.4em;
    letter-spacing: 0.1em;
    font-style: normal;
    font-weight: normal;
}

.Text--center {
    text-align: center;
}

.Text--bold {
    font-weight: 700;
}

.Text--sm {
    font-size: 13px;
}

.Text--md {
    font-size: 16px;
}

.Text--lg {
    font-size: 18px;
}

.Text--xl {
    font-size: 22px;
}
