.Shortcuts {
    padding: 20px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .Text {
        padding: 10px;
        margin: 10px;
    }

    a,a:hover {
        color: var(--kantherm-title);
        text-decoration: none;
    }
}
