
.Title {
  font-family: Quicksand;
  font-weight: 500;
  text-align: left;
  letter-spacing: 0.08em;
  color: var(--kantherm-title);
  margin-bottom: 14px;
}

.Title--xs {
  font-size: 15px;
}
.Title--sm {
  font-size: 21px;
}
.Title--md {
  font-size: 28px;
}
.Title--lg {
  font-size: 36px;
  font-weight: bold;
}

@media screen and (max-width: 1024px) {
  .Title--auto.Title--sm {
    font-size: 17px;
  }
  .Title--auto.Title--md {
    font-size: 25px;
  }
  .Title--auto.Title--lg {
    font-size: 34px;
  }
}
@media screen and (max-width: 574px) {
  .Title--auto.Title--sm {
    font-size: 16px;
  }
  .Title--auto.Title--md {
    font-size: 23px;
  }
  .Title--auto.Title--lg {
    font-size: 30px;
  }
}

.Title--bold {
  font-weight: bold;
}

.Title--center {
  text-align: center;
}
