.Cloud {
    
    display: flex;
    
    .Cloud__section {
        height: 700px;

        &--left {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .Cloud__img {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;
        width: 100%;
        height: 100%;
        
        display: flex;
        justify-content: center;
        align-items: center;
        > img {
            height: 80%;
        }
    }

    .Cloud__text {
        z-index: 1;
        margin: auto;
        max-width: 430px;

        .Cloud__title {
            font-family: Quicksand;
            
            color: var(--color-primary);
            margin-bottom: 30px;
            z-index: 1;
        }

        .Cloud__sub {
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.15em;

            // color: #252525;
            z-index: 1;
        }

        .Cloud__download {
            margin-top: 100px;
            
            display: flex;
            flex-direction: row;

            justify-content: space-evenly;
            flex-wrap: wrap;

            .Cloud__AppStore {
                padding: 12.5px;
            }

            a {
                height: 80px;
                // height: 75px;
                img {
                    height: 100%;
                }
            }
        }
    }
}
