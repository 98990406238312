.About {
    
    display: flex;
    
    .About__section {
        height: 300px;

        &--left {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .About__img {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;
        width: 100%;
        height: 100%;
        
        display: flex;
        justify-content: center;
        align-items: center;
        > img {
            height: 80%;
        }
    }

    .About__text {
        z-index: 1;
        margin: auto;
        max-width: 460px;

        .About__title {
            font-family: Quicksand;
            
            color: var(--color-primary);
            margin-bottom: 30px;
            z-index: 1;
        }

        .About__sub {
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.15em;

            // color: #252525;
            z-index: 1;
        }

        .About__download {
            margin-top: 100px;
            
        }
    }
}
