@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import './colors.scss';
// @import './hljs.scss';
// @import './mermaid.scss';
// @import './showdown.scss';
// @import './shadows.scss';

.bp3-overlay-backdrop {
    background-color: rgba(16, 22, 26, 0.2);
}

#root {
    --header-height: 80px;
    background-color: var(--kantherm-background);
    color: var(--kantherm-text);
  }