
:root {
    --color-primary: #004a7f;
    --color-primary-dark: #004a7f;
    --color-secondary: #0094ff;
    --color-secondary-dark: #0094ff;
    --color-contrast: #3F51B5;
    
    --color-text: #444444;
    --color-text-disabled: #a8a8a883;
    --color-title: rgb(128,128,128);

    --color-invalid: var(--color-bright-red);

    --color-button: hsl(196, 31%, 63%);
    --color-button-hover: hsl(196, 31%, 53%);
    --color-button-active: hsl(196, 31%, 43%);
    --color-button-border: var(--color-secondary-dark);
    --color-button-text: white;

    --color-marker: var(--color-secondary);
    --color-foreground: var(--color-primary--dark);
    --color-background: white;
    --color-topbar-background: white;

    --color-topbar-burger: hsla(0, 0%, 47%, 0.432);
    --color-topbar-burger-stripes: rgb(255, 255, 255);

    --color-topbar-button-active-text: var(--color-primary);
    --color-topbar-button-hover-bg: rgba(0, 0, 0, 0.075);
    
    --color-input-border: rgb(170, 170, 170);
    --color-input-border-error: var(--color-primary);
    --color-input-text: var(--color-text);
}