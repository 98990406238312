
.Layout {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;

    --paddingTop: 100px;
    --paddingBottom: 50px;
    --paddingSide: 0px;

    display: flex;
    flex-direction: row;
    padding: var(--paddingTop) var(--paddingSide) var(--paddingBottom) var(--paddingSide);

    > div {
        width: 100%;
    }

    
}

.Layout--vertical {
    flex-direction: column;
    
}

.Layout--auto {
    @media screen and (max-width: 899px) {
        flex-direction: column;   
    }
}

.Layout:not(.Layout--fullWidth) {
    --paddingSide: calc(100vw / 1440 * 100);
    @media screen and (max-width: 699px) {
        --paddingSide: 20px;
    }
    @media screen and (min-width: 1440px) {
        --paddingSide: calc((100% - 1440px) / 2 + 100px);
        
    }
}