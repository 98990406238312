.WseLogo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 80px;
    padding: 10px;

    > img {
        height: 100%;
    }

    > span {
        font-family: Quicksand;
        font-style: normal;
        font-weight: normal;
        font-size: 40.2245px;
        line-height: 39px;
        /* identical to box height */

        text-align: center;
        white-space: nowrap;

        color: #ffffff;
        margin: 0 20px;
    }
}



.WseLogo--medium {
    transform: scale(0.8);
}

.WseLogo--small {
    transform: scale(0.6);
}

.WseLogo--auto {
    @media (max-width: 999px) {
        transform: scale(0.8);
    }
    
    @media (max-width: 599px) {
        transform: scale(0.6);
    }
}